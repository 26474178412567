.section-dark {
  position: relative;
  z-index: 5;
  color: var(--baseTextLight);
  background-color: var(--section-dark-bg);

  &._section-dark-primary {
    padding-block: 80px;
    @include media-down(1440) {
      padding-block: 60px;
    }
    @include media-down(1024) {
      padding-block: 40px;
    }
  }
}
