.destinations {
  z-index: 6;
  background-image: url("../img/content/destinations/destinations-bg.jpg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  &:before,
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: auto;
    position: absolute;
    aspect-ratio: 1.001/1;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    pointer-events: none;
    @include media-down(1024) {
      display: none;
    }
  }

  &:before {
    top: 0;
    left: 0;
    max-width: 670px;
    transform: translate(-50%, -33%);
    background-image: url("../img/content/destinations/lyap-bg-1.png");
  }

  &:after {
    right: 0;
    bottom: 0;
    max-width: 620px;
    transform: translate(55%, 40%);
    background-image: url("../img/content/destinations/lyap-bg-2.png");
  }

  &__title-line {
    margin-bottom: 46px;
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: calc(100% + 16px);
    margin: -8px -8px 60px -8px;
    @include media-down(1200) {
      margin: -8px -8px 40px -8px;
    }
    @include media-down(576) {
      max-width: 360px;
      width: 100%;
      margin: 0 auto 40px;
    }
  }

  &__col {
    flex: 0 0 25%;
    width: 25%;
    padding: 8px;
    @include media-down(1200) {
      flex: 0 0 33.33333%;
      width: 33.33333%;
    }
    @include media-down(1024) {
      flex: 0 0 50%;
      width: 50%;
    }
    @include media-down(576) {
      flex: 0 0 100%;
      width: 100%;
      padding-inline: 0;
    }

    &:nth-child(3) {
      margin-right: 1px;
      @include media-down(1200) {
        margin: 0;
      }
    }
  }

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 382px;
    width: fit-content;
    margin: 0 auto;
    @include media-down(1024) {
      min-width: auto;
    }
    @include media-down(576) {
      width: 100%;
    }
  }
}
