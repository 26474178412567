.checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: px(26);
  min-width: px(26);
  height: px(26);
  position: relative;
  border: px(1) solid var(--gold-signature);
  transition: all .3s ease;
  cursor: pointer;
  @media (max-width: 1024px) {
    width: px(20);
    min-width: px(20);
    height: px(20);
  }

  &__input {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    appearance: none;
    cursor: pointer;
  }

  &__custom {
    opacity: 0;
    transform: scale(.5);
    transition: all .35s ease;

    .icon-checkbox {
      @media (max-width: 1024px) {
        width: px(14);
        min-width: px(14);
        height: px(14);
        min-height: px(14);
      }
    }
  }

  &__input:checked + &__custom {
    opacity: 1;
    transform: scale(1);
  }
}
