.field {
  width: 100%;
  position: relative;
  border-bottom: 1px solid var(--gold-signature);

  &__name {
    display: block;
    margin-bottom: px(5);
    color: var(--silver);
    font-weight: 300;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all .3s ease;
    pointer-events: none;

    &._move-up {
      left: 0;
      top: 0;
      transform: translate(0, -75%);
    }
  }

  &__input {
    width: 100%;
    min-height: 48px;
    padding: 12px 0;
    color: var(--ivory-1);
    font-weight: 300;
    line-height: 1;
    background-color: transparent;
    border: 0;
  }
}
