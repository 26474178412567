.concierge-services {
  background-image: url("../img/content/inspired-experiences/inspired-experiences-bg.png");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  &__title-line {
    margin-bottom: 46px;
  }

  &__row {
    display: flex;
    width: 100%;
    max-width: 100%;
    min-height: 800px;
    transition: all .5s ease;
    @media (max-width: 1620px) {
      min-height: 700px;
    }
    @media (max-width: 1440px) {
      min-height: 600px;
    }
    @media (max-width: 1024px) {
      flex-wrap: wrap;
    }
  }

  &__col {
    flex: 1 0 auto;
    transition: all .5s ease;
    @media (min-width: 1025px) {
      &:hover {
        flex: 2.7157 0 auto;
      }
    }
    @media (max-width: 1024px) {
      flex: 0 0 50%;
    }
    @media (max-width: 576px) {
      flex: 0 0 100%;
    }
  }
}
