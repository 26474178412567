.socials {
  @include media-up(1025) {
    &:hover {
      .socials__link:not(:hover) {
        transform: scale(.9);
      }

      .socials__link:hover {
        transform: scale(1.05);

        .icon {
          &-s-instagram,
          &-s-linkedin {
            fill: var(--ivory-1);
          }

          &-s-facebook {
            stroke: var(--ivory-1);
          }
        }
      }
    }
  }

  &__row {
    display: flex;
    width: calc(100% + 24px);
    margin-inline: -12px;
    pointer-events: none;
  }

  &__col {
    padding-inline: 12px;
    pointer-events: none;
  }

  &__link {
    display: block;
    padding: 0;
    border-radius: 50%;
    pointer-events: auto;
    transition: all .3s ease;
  }
}
