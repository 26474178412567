.events {
  &__title-line {
    margin-bottom: 46px;
    @include media-down(576) {
      margin-bottom: 28px;
    }
  }

  &__slider {
    //margin-bottom: 83px;
    margin-bottom: 104px;
    @media (max-width: 1620px) {
      margin-bottom: 84px;
    }
    @media (max-width: 1440px) {
      margin-bottom: 60px;
    }
    @media (max-width: 1024px) {
      margin-bottom: 20px;
    }

    .slider-container  {
      position: relative;
    }

    .swiper-wrapper {
      //margin-bottom: 21px;
    }

    .swiper-slide {
      //width: 64%;
      //height: 638px;
      aspect-ratio: 1.92633/1;
      overflow: hidden;
      position: relative;
      @media (max-width: 1024px) {
        aspect-ratio: 1.5/1;
      }
      @media (max-width: 576px) {
        aspect-ratio: 1/1;
      }

      &:before,
      &:after {
        display: block;
        position: absolute;
        pointer-events: none;
      }

      &:before {
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 2;
        background: rgba(0, 0, 0, 0.32);
      }

      &:after {
        content: attr(data-slide-title);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: fit-content;
        height: fit-content;
        min-height: 116px;
        padding: 20px;
        color: var(--ivory-1);
        text-align: center;
        leading-trim: both;
        text-edge: cap;
        font-family: "PP Hatton", sans-serif;
        font-size: 30px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        text-transform: uppercase;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 3;
        background: rgba(20, 18, 20, 0.53);
        @media (min-width: 1025px) {
          display: none;
        }
        @media (max-width: 768px) {
          font-size: 24px;
          min-height: 100px;
        }
        @media (max-width: 576px) {
          font-size: 18px;
        }
      }

      &__image {
        width: 100%;
        height: 100%;
      }

      &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .swiper-pagination {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 174px;
      width: calc(100% + 68px);
      margin-inline: -34px;
      position: relative;
      @media (max-width: 1620px) {
        width: calc(100% + 40px);
        margin-inline: -20px;
        height: 144px;
      }
      @media (max-width: 1440px) {
        height: 119px;
      }
      @media (max-width: 1024px) {
        display: none;
      }

      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        position: absolute;
        left: 0;
        top: 65px;
        background-color: var(--gold-signature);
        @media (max-width: 1620px) {
          top: 52.5px;
        }
        @media (max-width: 1440px) {
          top: 40.5px;
        }
      }

      .swiper-pagination-bullet {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding-inline: 34px;
        color: var(--grey-dark);
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 200;
        line-height: normal;
        position: relative;
        z-index: 2;
        transition: all .5s ease;
        @media (max-width: 1620px) {
          padding-inline: 20px;
          font-size: 20px;
        }

        &.swiper-pagination-bullet-active {
          padding: 0;
          font-size: 48px;
          transform: translateY(22px);
          @media (max-width: 1620px) {
            font-size: 40px;
            transform: translateY(18px);
          }
          @media (max-width: 1440px) {
            font-size: 36px;
            transform: translateY(13px);
          }
          @media (max-width: 1200px) {
            font-size: 28px;
            transform: translateY(5px);
          }

          .swiper-pagination-bullet-circle {
            width: 83px;
            margin-bottom: 26px;
            background-color: transparent;
            @media (max-width: 1620px) {
              width: 70px;
              margin-bottom: 20px;
            }
            @media (max-width: 1440px) {
              width: 55px;
              margin-bottom: 15px;
            }
            @media (max-width: 1200px) {
              width: 40px;
              margin-bottom: 10px;
            }
          }
        }
      }

      .swiper-pagination-bullet-circle {
        display: block;
        width: 18px;
        min-width: 18px;
        aspect-ratio: 1/1;
        margin-bottom: 11px;
        border: 1px solid var(--gold-signature);
        background-color: var(--gold-signature);
        transition: all .5s ease;
        border-radius: 50%;
        @media (max-width: 1200px) {
          width: 15px;
          min-width: 15px;
        }
      }
    }
  }

  &__slider-btns {
    position: absolute;
    top: 39%;
    left: 64.5%;
    z-index: 5;
    transform: translate(-50%, -50%);
    @media (max-width: 1024px) {
      display: flex;
      justify-content: flex-end;
      gap: 20px;
      padding: 20px;
      position: static;
      transform: none;
      top: unset;
      left: unset;
    }
  }

  &__slider-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: auto;
    aspect-ratio: 1/1;
    border-radius: 254px;
    border: 2px solid var(--gold-signature);
    transition: all .5s ease;
    @media (min-width: 1025px) {
      background: rgba(20, 18, 20, 0.05);
      backdrop-filter: blur(19px);

      &:hover {
        background: rgba(255, 255, 255, 0.2);

        .icon {
          transform: scale(1.05);
        }
      }
    }
    @media (max-width: 1620px) {
      width: 150px;
    }
    @media (max-width: 1440px) {
      width: 125px;
    }
    @media (max-width: 1200px) {
      width: 90px;
    }
    @media (max-width: 1024px) {
      border: 1px solid var(--gold-signature);
      width: 60px;
    }

    &.prev {
      @media (min-width: 1025px) {
        display: none;
      }
    }

    .icon {
      width: 108px;
      height: 57px;
      stroke-width: 2px;
      stroke: var(--gold-signature);
      transform-origin: center center;
      @media (max-width: 1620px) {
        width: 76px;
        height: 44px;
      }
      @media (max-width: 1440px) {
        width: 60px;
        height: 32px;
      }
      @media (max-width: 1200px) {
        width: 40px;
        height: 21px;
      }
      @media (max-width: 1024px) {
        width: 30px;
        height: 15px;
        stroke-width: 3px;
      }
    }
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    @include media-up(769) {
      min-width: 333px;
    }
  }
}
