.form {
  &__row {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + px(40));
    margin-inline: px(-20);
    row-gap: 20px;
    @media (max-width: 576px) {
      width: calc(100% + px(20));
      margin-inline: px(-10);
    }
  }

  &__col {
    width: 33.33333%;
    flex: 0 0 33.33333%;
    padding-inline: px(20);
    position: relative;
    @media (min-width: 1025px) {
      &:not(:last-child) {
        &:after {
          content: '';
          display: block;
          width: 1px;
          height: 40px;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translate(50%, -50%);
          background-color: rgba(255, 255, 255, 0.10);
        }
      }
    }
    @media (max-width: 576px) {
      width: 100%;
      flex: 0 0 100%;
    }
  }

  &__agreement {
    display: flex;
    align-items: center;
    @media (max-width: 1024px) {
      gap: 20px;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__agreement-policy {
    margin-right: 80px;
    @media (max-width: 1024px) {
      margin-right: 0;
    }
  }

  &__agreement-label {
    display: flex;
    align-items: center;
    color: var(--ivory-2);
    font-size: 16px;
    line-height: 160%;
    cursor: pointer;
    @media (max-width: 576px) {
      font-size: 14px;
    }

    a {
      color: var(--ivory-2);
      font-size: 16px;
      line-height: 160%;
      text-decoration: underline;
      @media (max-width: 576px) {
        font-size: 14px;
      }
    }

    .checkbox {
      margin-right: 20px;
      @media (max-width: 576px) {
        margin-right: 10px;
      }
    }
  }

  &__submit {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    margin: 0 auto;
    line-height: 1;
    padding-inline: 80px;
  }
}
