.testimonials {
  z-index: 6;
  background-image: url("../img/content/destinations/destinations-bg.jpg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  &:before,
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: auto;
    position: absolute;
    aspect-ratio: 1.001/1;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    pointer-events: none;
    @include media-down(1024) {
      display: none;
    }
  }

  &:after {
    bottom: 0;
    left: 0;
    max-width: 670px;
    transform: translate(-32.5%, 36%) scale(-1, 1) rotate(140deg);
    background-image: url("../img/content/destinations/lyap-bg-2.png");
  }

  &:before {
    right: 0;
    top: 0;
    max-width: 620px;
    transform: translate(43%, -37%) scale(-1, 1) rotate(140deg);
    background-image: url("../img/content/destinations/lyap-bg-2.png");
  }

  &__wrap {
    & > * {
      position: relative;
      z-index: 2;
    }

    &:before {
      content: '';
      display: block;
      max-width: 490px;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 50%;
      z-index: 1;
      transform: translateX(-50%);
      background: rgba(20, 18, 20, 0.63);
      backdrop-filter: blur(5.5px);
      @media (max-width: 1440px) {
        max-width: 450px;
      }
      @media (max-width: 576px) {
        display: none;
      }
    }
  }

  &__title {
    margin-bottom: 60px;
    @media (max-width: 1024px) {
      margin-bottom: 40px;
    }
  }

  &__slider {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 40px;

    .slider-container {
      width: 700px;
      padding-top: 113px;
      overflow: hidden;
      @media (max-width: 1440px) {
        width: 550px;
        padding-top: 90px;
      }
      @media (max-width: 1024px) {
        padding-top: 60px;
      }
    }
  }

  &__slider-btn {
    margin-top: 113px;
    @media (max-width: 1440px) {
      margin-top: 90px;
    }
    @media (max-width: 1024px) {
      display: none;
    }
  }

  &__descr {
    max-width: 490px;
    padding-inline: 44px;
    margin: 0 auto 60px;
    text-align: center;
    @media (max-width: 1440px) {
      max-width: 450px;
      margin: 0 auto 40px;
    }
    @media (max-width: 576px) {
      max-width: 100%;
      width: 100%;
      padding: 0;
    }
  }

  &__descr-content {
    margin-bottom: 24px;
    color: var(--ivory-1);
  }

  &__name {
    display: block;
    color: var(--ivory-1);
    font-weight: 600;
  }

  &__detail {
    display: block;
    color: var(--ivory-1);
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 385px;
    width: fit-content;
    margin: 0 auto;
    @media (max-width: 576px) {
      min-width: 100%;
    }
  }
}
