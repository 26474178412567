.partners {
  &__title-line {
    max-width: 100px;
    margin-bottom: 46px;
    @media (max-width: 1440px) {
      margin-bottom: 30px;
    }
  }

  &__slider {
    width: 100%;

    .slider-container {
      width: 100%;
      overflow: hidden;
      cursor: grab;
    }

    .swiper-slide {
      &__img {
        width: 100%;
        height: 100%;
        aspect-ratio: 1/1;
        object-fit: contain;
      }
    }
  }
}
