:root {
  // === Colors
  // Black
  --baseBlack: #{$baseBlack};

  // White
  --baseWhite: #{$baseWhite};
  --ivory-1: #{$ivory-1};
  --ivory-2: #{$ivory-2};

  // Blue
  --blue-deep: #{$blue-deep};
  --blue-oxford-1: #{$blue-oxford-1};
  --blue-oxford-2: #{$blue-oxford-2};

  // Grey
  --grey-dark: #{$grey-dark};

  // Silver
  --silver: #{$silver};

  // Gold
  --gold-signature: #{$gold-signature};

  // Green
  --green-deep: #{$green-deep};
  --green-olive-1: #{$green-olive-1};
  --green-olive-2: #{$green-olive-2};
  --green-olive-3: #{$green-olive-3};
  --green-olive-4: #{$green-olive-4};

  // === Layout colors
  --body-bg: var(--ivory-1);
  --section-light-bg: var(--ivory-1);
  --section-dark-bg: var(--green-deep);

  // === Topography
  --baseSectionTitle: var(--gold-signature);
  --baseTextLight: var(--ivory-2);
  --baseTextDark: var(--grey-dark);

  // === Sizes
  --header-height: #{px(140)};
  --current-scrollbar-width: 0px;
}
