.make-different {
  &__title-line {
    margin-bottom: 46px;
  }

  &__grid {
    display: grid;
    gap: 20px;
    @media (min-width: 1025px) {
      grid-auto-flow: row;
      grid-template-columns: repeat(24, 1fr);
      grid-template-rows: repeat(4, minmax(130px, 1fr));
      grid-auto-columns: 1fr;
      grid-template-areas:
    "_poster-1_4-3 _poster-1_4-3 _poster-1_4-3 _poster-1_4-3 _poster-1_4-3 _poster-1_4-3 _poster-1_4-3 _poster-1_4-3 _text-1_2-1 _text-1_2-1 _text-1_2-1 _text-1_2-1 _text-2_2-1 _text-2_2-1 _text-2_2-1 _text-2_2-1 _poster-2_2-2 _poster-2_2-2 _poster-2_2-2 _poster-2_2-2 _text-3_2-1 _text-3_2-1 _text-3_2-1 _text-3_2-1"
    "_poster-1_4-3 _poster-1_4-3 _poster-1_4-3 _poster-1_4-3 _poster-1_4-3 _poster-1_4-3 _poster-1_4-3 _poster-1_4-3 _poster-3_4-1 _poster-3_4-1 _poster-3_4-1 _poster-3_4-1 _poster-3_4-1 _poster-3_4-1 _poster-3_4-1 _poster-3_4-1 _poster-2_2-2 _poster-2_2-2 _poster-2_2-2 _poster-2_2-2 _text-4_2-1 _text-4_2-1 _text-4_2-1 _text-4_2-1"
    "_poster-1_4-3 _poster-1_4-3 _poster-1_4-3 _poster-1_4-3 _poster-1_4-3 _poster-1_4-3 _poster-1_4-3 _poster-1_4-3 _text-5_2-1 _text-5_2-1 _text-5_2-1 _text-5_2-1 _text-6_2-1 _text-6_2-1 _text-6_2-1 _text-6_2-1 _text-7_2-1 _text-7_2-1 _text-7_2-1 _text-7_2-1 _poster-4_2-2 _poster-4_2-2 _poster-4_2-2 _poster-4_2-2"
    "_poster-5_3-1 _poster-5_3-1 _poster-5_3-1 _poster-5_3-1 _poster-5_3-1 _poster-5_3-1 _poster-6_3-1 _poster-6_3-1 _poster-6_3-1 _poster-6_3-1 _poster-6_3-1 _poster-6_3-1 _text-8_2-1 _text-8_2-1 _text-8_2-1 _text-8_2-1 _poster-7_2-1 _poster-7_2-1 _poster-7_2-1 _poster-7_2-1 _poster-4_2-2 _poster-4_2-2 _poster-4_2-2 _poster-4_2-2";

      ._poster-1_4-3 {
        grid-area: _poster-1_4-3;
      }

      ._text-1_2-1 {
        grid-area: _text-1_2-1;
      }

      ._text-2_2-1 {
        grid-area: _text-2_2-1;
      }

      ._poster-2_2-2 {
        grid-area: _poster-2_2-2;
      }

      ._text-3_2-1 {
        grid-area: _text-3_2-1;
      }

      ._poster-3_4-1 {
        grid-area: _poster-3_4-1;
      }

      ._text-4_2-1 {
        grid-area: _text-4_2-1;
      }

      ._text-5_2-1 {
        grid-area: _text-5_2-1;
      }

      ._text-6_2-1 {
        grid-area: _text-6_2-1;
      }

      ._text-7_2-1 {
        grid-area: _text-7_2-1;
      }

      ._poster-4_2-2 {
        grid-area: _poster-4_2-2;
      }

      ._poster-5_3-1 {
        grid-area: _poster-5_3-1;
      }

      ._poster-6_3-1 {
        grid-area: _poster-6_3-1;
      }

      ._text-8_2-1 {
        grid-area: _text-8_2-1;
      }

      ._poster-7_2-1 {
        grid-area: _poster-7_2-1;
      }
    }
    @media (min-width: 1201px) {
      grid-template-rows: repeat(4, minmax(160px, 1fr));
    }
    @media (max-width: 1024px) {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(2, minmax(130px, 1fr));
    }
    @media (max-width: 768px) {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(3, minmax(130px, 1fr));
    }
    @media (max-width: 576px) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(4, minmax(130px, 1fr));
      gap: 10px;
    }
  }

  &__grid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    color: var(--grey-dark);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 200;
    line-height: 150%;
    position: relative;
    overflow: hidden;
    transition: all .5s ease;
    @media (max-width: 1440px) {
      font-size: 16px;
    }

    &._border {
      border: 1px solid var(--gold-signature);
    }

    &._poster {
      @media (max-width: 1024px) {
        display: none;
      }
    }

    &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .icon {
      &-crown {
        margin-inline: auto;
        margin-bottom: 12px;
        @media (max-width: 1200px) {
          width: 25px;
          min-width: 25px;
          height: 25px;
          margin-bottom: 8px;
        }

        &.silver {
          fill: var(--silver);
        }
      }
    }
  }
}
