// === General Sans
// Variable
@include font('General Sans', 'GeneralSans-Variable', 1 999, normal);
@include font('General Sans', 'GeneralSans-VariableItalic', 1 999, italic);

// 200
@include font('General Sans', 'GeneralSans-Extralight', 200, normal);
@include font('General Sans', 'GeneralSans-ExtralightItalic', 200, italic);

// 300
@include font('General Sans', 'GeneralSans-Light', 300, normal);
@include font('General Sans', 'GeneralSans-LightItalic', 300, italic);

// 400
@include font('General Sans', 'GeneralSans-Regular', 400, normal);
@include font('General Sans', 'GeneralSans-Italic', 400, italic);

// 500
@include font('General Sans', 'GeneralSans-Medium', 500, normal);
@include font('General Sans', 'GeneralSans-MediumItalic', 500, italic);

// 600
@include font('General Sans', 'GeneralSans-Semibold', 600, normal);
@include font('General Sans', 'GeneralSans-SemiboldItalic', 600, italic);

// 700
@include font('General Sans', 'GeneralSans-Bold', 700, normal);
@include font('General Sans', 'GeneralSans-BoldItalic', 700, italic);

// === PP Hatton
// 200
@include font('PP Hatton', 'PPHatton-Ultralight', 200, normal);

// 300
@include font('PP Hatton', 'PPHatton-Light', 300, normal);
