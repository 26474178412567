.burger-btn {
  display: block;
  width: px(40);
  min-width: px(30);
  height: px(20);
  position: relative;

  &.active {
    &:before {
      width: 120%;
    }

    i:nth-child(1),
    i:nth-child(2),
    i:nth-child(3) {
      top: 50%;
      transform: translateY(-50%);
    }
  }

  i,
  &:before {
    display: block;
    height: px(2);
    position: absolute;
    background-color: var(--gold-signature);
    transition: all .3s ease;
  }

  &:before {
    content: '';
    width: 0;
    left: -10%;
    top: -10%;
    transform-origin: left center;
    transform: rotate(24deg);
  }

  i {
    left: 0;
    width: 100%;


    &:nth-child(1) {
      top: 0;
    }

    &:nth-child(2) {
      top: 50%;
      transform: translateY(-50%);
    }

    &:nth-child(3) {
      top: 100%;
      transform: translateY(-100%);
    }
  }
}
