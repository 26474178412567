.destinations-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1.129943;
  overflow: hidden;
  //border: 1px solid transparent;
  transition: border .75s ease;

  &:active,
  &:hover {
    //border-color: var(--gold-signature);
    border: 1px solid var(--gold-signature);

    .destinations-card__top {
      margin-top: -90%;
    }

    .destinations-card__top-content {
      background: rgba(38, 36, 38, 0);
      backdrop-filter: blur(0);
    }
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex: 0 0 100%;
    width: 100%;
    height: 100%;
    transition: all .75s ease;

    &__img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }
  }

  &__top-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 2;
    background: rgba(38, 36, 38, 0.40);
    backdrop-filter: blur(8.5px);
    transition: all .75s ease;

    .icon {
      width: 130px;
      height: 100px;
      margin-bottom: 16px;
      fill: var(--gold-signature);
      @include media-down(1440) {
        width: 110px;
        height: 80px;
      }
      @include media-down(1200) {
        width: 90px;
        height: 70px;
      }
      @include media-down(1024) {
        width: 70px;
        height: 50px;
      }
    }
  }

  &__top-title {
    color: var(--ivory-1);
    text-align: center;
    font-family: "PP Hatton", sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    @include media-down(1200) {
      font-size: 28px;
    }
    @include media-down(1024) {
      font-size: 24px;
    }
  }

  &__bottom {
    flex: 0 0 90%;
    height: 90%;
  }

  &__bottom-content {
    width: 100%;
    height: 100%;
    padding: 20px;
    background-color: var(--ivory-1);
  }

  &__bottom-title {
    margin-bottom: 16px;
    text-align: center;
    color: #262426;
    font-family: "PP Hatton", sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    @include media-down(1200) {
      font-size: 28px;
    }
    @include media-down(1024) {
      font-size: 24px;
    }
  }

  &__bottom-descr {
    color: var(--grey-dark);
  }
}
