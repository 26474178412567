.hero {
  height: 100vh;
  min-height: 900px;
  padding: calc(var(--header-height) + 100px) 0 100px;
  position: relative;
  z-index: 4;
  //overflow: hidden;
  @include media-down(1200) {
    height: fit-content;
  }
  @media (max-height: 700px) {
    height: fit-content;
  }

  &__bg-wrap {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    overflow: hidden;
    pointer-events: none;
  }

  &__bg {
    width: 100%;
    height: 100%;
    position: relative;
    transform: scale(1.3);

    &:after,
    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
    }

    &:after {
      background: linear-gradient(0deg, rgba(18, 36, 31, 0.60) 0%, rgba(0, 0, 0, 0.00) 75.86%);
    }

    &:before {
      background: linear-gradient(180deg, #12241F 0%, rgba(0, 0, 0, 0.00) 82.29%);
    }

    &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 2;
  }

  &__box {
    width: 100%;
    padding-block: 60px;
    background: rgba(20, 18, 20, 0.53);
    @include media-down(1440) {
      padding-block: 45px;
    }
    @include media-down(1024) {
      padding-block: 30px;
    }
  }

  &__title {
    margin-bottom: 40px;
    @include media-down(1200) {
      margin-bottom: 30px;
    }
  }

  &__search-form-row {
    display: flex;
    width: calc(100% + 24px);
    margin-inline: -12px;
    @include media-down(1200) {
      flex-wrap: wrap;
      row-gap: 30px;
    }
  }

  &__search-form-col {
    flex: 0 0 16.444444%;
    width: 16.444444%;
    padding-inline: 12px;
    opacity: 0;
    @include media-down(1200) {
      flex: 0 0 20%;
      width: 20%;
    }
    @include media-down(1024) {
      flex: 0 0 33.33333%;
      width: 33.33333%;
    }
    @include media-down(768) {
      flex: 0 0 50%;
      width: 50%;

      &:nth-child(1),
      &:nth-child(6) {
        flex: 0 0 100%;
        width: 100%;
      }
    }
    @include media-down(768) {
      flex: 0 0 100%;
      width: 100%;
    }

    &:last-child {
      @include media-up(1201) {
        flex: 0 0 17.2%;
        width: 17.2%;
        margin-left: auto;
      }
      @include media-between(1025, 1200) {
        flex: 0 0 30%;
        width: 30%;
        margin: 0 auto;
      }
    }
  }

  &__search-form-field {
    position: relative;

    @include media-up(1025) {
      &:hover {
        ._select-trigger,
        &__search-form-field-name {
          color: var(--gold-signature);
        }
      }
    }
  }

  &__search-form-field-name,
  &__search-form-field-input {
    color: var(--ivory-1);
    font-size: 16px;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
    transition: all .3s ease;
    @include media-down(1440 ) {
      font-size: 15px;
    }
  }

  &__search-form-field-name {
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
    pointer-events: none;
    @include media-down(1440 ) {
      left: 8px;
    }

    &._move-up {
      top: 0;
      left: 0;
      transform: translateY(-100%);
    }
  }

  &__search-form-field-input-container {
    display: flex;
    align-items: center;
    border-radius: 1px;
    border-bottom: 1px solid var(--gold-signature);
  }

  &__search-form-field-input {
    display: block;
    width: 100%;
    padding: 8px 8px 8px 0;
    text-align: start;
    background-color: transparent;
    border: 0;
    @include media-down(1440 ) {
      padding: 6px 6px 6px 0;
    }
  }

  &__search-form-submit {
    width: 100%;
    padding-block: 10px;
    color: var(--ivory-1);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    @include media-down(1440) {
      padding-block: 8px;
      font-size: 16px;
    }
  }
}
