.breadcrumbs {
  padding-block: px(20);

  &__list {
    display: flex;
    width: calc(100% + px(20));
    margin-inline: px(-10);

    li {
      padding-inline: px(10);
      position: relative;

      &:not(:last-child) {
        &:after {
          content: '>';
          font-size: px(10);
          position: absolute;
          top: 50%;
          right: 0;
          transform: translate(50%, -50%);
          pointer-events: none;
        }
      }

      &:last-child {
        a {
          pointer-events: none;
          color: #ca66fb;
        }
      }
    }
  }
}
