.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  background-color: rgba($baseBlack, .5);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;

  &.show {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }

  &__dialog {
    max-width: 600px;
    width: 100%;
    position: relative;
    background-color: $baseWhite;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: px(30) px(20) px(10);
  }

  &__header-close-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: px(30);
    min-width: px(30);
    height: px(30);
  }

  &__body {
    padding: px(10) px(20) px(30);
  }
}
