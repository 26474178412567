.contact-form {
  background-image: url("../img/content/inspired-experiences/inspired-experiences-bg.png");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  &._section-dark-primary {
    padding-bottom: 144px;
    @media (max-width: 1620px) {
      padding-bottom: 120px;
    }
    @media (max-width: 1440px) {
      padding-bottom: 80px;
    }
    @media (max-width: 1200px) {
      padding-bottom: 60px;
    }
  }

  &__title {
    margin-bottom: 60px;
    text-transform: uppercase;
  }

  &__form {
    max-width: 955px;
    width: 100%;
    margin: 0 auto;

    .form__agreement,
    .form__row {
      &:not(:last-child) {
        margin-bottom: 60px;
        @media (max-width: 1024px) {
          margin-bottom: 40px;
        }
      }
    }

    .form__col {
      @media (max-width: 768px) {
        flex: 0 0 50%;

        &:nth-child(3) {
          flex: 0 0 100%;
        }
      }
      @media (max-width: 576px) {
        flex: 0 0 100%;
      }
    }
  }
}
