.logo-primary {
  display: inline-block;
  max-width: px(133);

  &__image,
  &__img {
    width: 100%;
    max-width: 100%;
    height: auto;
    object-fit: contain;
  }
}
