.header {
  width: 100%;
  padding: px(20) var(--current-scrollbar-width) px(20) 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  background-color: var(--section-dark-bg);
  transition: all 0.5s ease, padding-right 0s;
  @include media-down(1200) {
    padding: 10px var(--current-scrollbar-width) 10px 0;
  }

  &.hide {
    transform: translateY(calc(-100% - 20px));
  }

  &__tablet {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 2100;
    @include media-up(1201) {
      display: none;
    }
  }

  &__logo {
    max-width: 40px;
  }

  &__lang-dropdown-hover-primary {
    width: fit-content;
    margin-left: auto;
    margin-right: 20px;

    .nav__lang-btn {
      @include media-down(1200) {
        padding: 5px;
        width: 35px;
        min-width: 35px;
        height: 35px;
        font-size: 14px;
      }
    }

    &.active {
      .dropdown-hover-primary__body {
        transform: translate(-50%, 0);
      }
    }

    .dropdown-hover-primary__body {
      width: 100%;
      min-width: 60px;
      padding-top: 8px;
      left: 50%;
      transform: translate(-50%, -15px);
    }

    .dropdown-hover-primary__list {
      width: 100%;
      padding: 8px;
    }
  }
}
