.select {
  width: 100%;
  position: relative;

  &.active {
    .icon-arrow-down {
      transform: rotate(-180deg);
    }

    .select__body {
      transform: translateY(0);
      opacity: 1;
      visibility: visible;
      pointer-events: all;
    }
  }

  &__body {
    min-width: 240px;
    width: fit-content;
    padding-top: 16px;
    position: absolute;
    top: 100%;
    z-index: 10;
    transform: translateY(-10px);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all .5s ease;
  }

  &__list {
    padding: 16px;
    background-color: var(--ivory-1);
    border: 1px solid var(--gold-signature);
  }

  &__link {
    display: block;
    width: 100%;
    padding: 12px 0;
    position: relative;
    color: var(--graydark);
    text-align: start;
    font-size: 18px;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
    transition: all .5s ease;

    @include media-up(1025) {
      &:hover {
        font-weight: 300;
        color: var(--baseWhite);

        &:before {
          width: calc(100% + 32px);
          background-color: var(--gold-signature);
        }
      }
    }

    &:before {
      content: '';
      display: block;
      width: 100%;
      height: calc(100% + 1px);
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      z-index: 1;
      border-bottom: 1px solid var(--gold-signature);
      transition: all .5s ease;
      pointer-events: none;
    }


    &-inner {
      position: relative;
      z-index: 2;
    }
  }
}
