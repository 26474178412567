.btn {
  position: relative;
  padding: 18px 30px 19px;
  font-family: "General Sans", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  transition: all .75s ease;
  overflow: hidden;
  @include media-up(1025) {
    &:hover {
      .btn__bg {
        opacity: 1;
        width: 300%;
        transition: opacity .45s, width 1.5s;
      }
    }
  }
  @include media-down(1440) {
    padding: 16px 24px;
    font-size: 18px;
  }


  &__inner {
    position: relative;
    z-index: 2;
  }

  &__bg {
    width: 0;
    height: auto;
    aspect-ratio: 1/1;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
    transform-origin: center center;
    z-index: 1;
    border-radius: 50%;
    pointer-events: none;
    opacity: 0;
    transition: opacity .45s, width .35s;
    background-color: var(--ivory-1);
  }
}
