// Black
$baseBlack: #000;

// White
$baseWhite: #fff;
$ivory-1: #FFFFF2;
$ivory-2: #FFFFFA;

// Blue
$blue-deep: #1C1F29;
$blue-oxford-1: #002E4A;
$blue-oxford-2: #003657;

// Grey
$grey-dark: #262426;

// Silver
$silver: #ABADB0;

// Gold
$gold-signature: #C7AB80;

// Green
$green-deep: #12241F;
$green-olive-1: #0A2E26;
$green-olive-2: #143D2E;
$green-olive-3: #034733;
$green-olive-4: #14543D;
