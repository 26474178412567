.icon {
  display: block;
  transition: all 0.3s ease;

  &-crown {
    fill: var(--gold-signature);
  }

  &-phone {
    fill: none;
    stroke: var(--gold-signature);
    stroke-width: 1px;
  }

  &-phone {
    width: 24px;
    min-width: 24px;
    height: 24px;
  }

  &-crown {
    width: 37px;
    min-width: 37px;
    height: 40px;
    min-height: 40px;
  }

  &-s-facebook,
  &-s-instagram,
  &-s-linkedin {
    width: 40px;
    min-width: 40px;
    height: 40px;
    min-height: 40px;
    @include media-down(1620) {
      width: 35px;
      min-width: 35px;
      height: 35px;
      min-height: 35px;
    }
    @include media-down(1440) {
      width: 30px;
      min-width: 30px;
      height: 30px;
      min-height: 30px;
    }
  }

  &-s-instagram,
  &-s-linkedin {
    fill: var(--gold-signature);
  }

  &-s-facebook {
    fill: none;
    stroke-width: 1px;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: var(--gold-signature);
  }

  &-search,
  &-date,
  &-bed,
  &-arrow-down {
    width: 24px;
    min-width: 24px;
    height: 24px;
    min-height: 24px;
    @include media-down(1440) {
      width: 20px;
      min-width: 20px;
      height: 20px;
      min-height: 20px;
    }
  }

  &-search,
  &-date,
  &-bed {
    fill: var(--ivory-1);
  }

  &-arrow-down {
    fill: none;
    stroke: var(--ivory-1);
  }

  &-people,
  &-bathroom {
    width: 40px;
    min-width: 40px;
    height: 40px;
    min-height: 40px;
    fill: var(--gold-signature);
  }

  &-checkbox {
    width: 20px;
    min-width: 20px;
    height: 20px;
    min-height: 20px;
    fill: var(--gold-signature);
  }
}
