.section-light {
  position: relative;
  z-index: 5;
  color: var(--baseTextDark);
  background-color: var(--body-bg);

  &._section-light-primary {
    padding-block: 80px;
    @include media-down(1440) {
      padding-block: 60px;
    }
    @include media-down(1024) {
      padding-block: 40px;
    }
  }
}
