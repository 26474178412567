.primary-btn {
  background-color: var(--gold-signature);
  color: var(--ivory-1);

  @include media-up(1045) {
    &:hover {
      color: var(--gold-signature);
    }
  }

  &._dark {
    @include media-up(1045) {
      &:hover {
        color: var(--ivory-1);
      }
    }

    .primary-btn__bg {
      background-color: var(--grey-dark);
    }
  }
}
