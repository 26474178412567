.testimonials-card {
  display: flex;
  flex-direction: column;
  padding: 0 64px 32px;
  background-color: var(--baseWhite);
  @media (max-width: 1440px) {
    padding: 0 40px 20px;
  }
  @media (max-width: 576px) {
    padding: 0 20px 20px;
  }

  &__picture {
    width: 226px;
    aspect-ratio: 1/1;
    margin: -113px auto 11px;
    padding: 13px;
    border-radius: 50%;
    border: 1px solid var(--gold-signature);
    overflow: hidden;
    @media (max-width: 1440px) {
      width: 180px;
      margin: -90px auto 11px;
    }
    @media (max-width: 1024px) {
      width: 120px;
      margin: -60px auto 11px;
      padding: 8px;
    }

    &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  &__descr {
    margin-bottom: 17px;
  }

  &__content {
    color: var(--grey-dark);
    text-align: center;
  }

  &__author {
    display: block;
    color: var(--grey-dark);
    text-align: center;
    font-weight: 600;
  }

  &__detail-location {
    display: block;
    color: var(--grey-dark);
    text-align: center;
  }
}
