.btn-slider-primary {
  position: relative;
  transition: all .3s ease;

  @include media-up(1025) {
    &:hover:not(:active) {
      .btn-slider-primary__circle {
        width: 43px;
        @include media-down(1440) {
          width: 25px;
        }
      }
    }
  }

  &.swiper-button-disabled {
    pointer-events: none;
    opacity: .5;

    .btn-slider-primary__circle {
      transform: translate(-50%, -50%) scale(0);
      opacity: 0;
    }
  }

  .icon {
    width: 130px;
    height: 41px;
    fill: none;
    stroke-width: 2px;
    stroke: var(--gold-signature);
    stroke-linecap: round;
    stroke-linejoin: round;
    @include media-down(1440) {
      width: 110px;
      height: 32px;
    }
    @include media-down(1200) {
      width: 90px;
      height: 25px;
    }
  }

  &__circle {
    display: block;
    width: 17px;
    aspect-ratio: 1/1;
    height: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid var(--gold-signature);
    border-radius: 50%;
    transition: width .35s ease;
    pointer-events: none;
    @include media-down(1440) {
      width: 15px;
    }
  }
}
