* {
  &::-webkit-scrollbar {
    width: px(4);
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--gold-signature);
  }
}

html {
  //overflow: hidden;
}

html,
body {
  &.scroll-locked {
    overflow: hidden;
  }
}

body {
  padding-right: var(--current-scrollbar-width);
  font-family: 'General Sans', sans-serif;
  font-weight: 200;
  line-height: 150%;
  font-size: 18px;
  font-style: normal;
  color: var(--baseTextDark);
  background-color: var(--body-bg);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  @include media-down(1440) {
    font-size: 16px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

ul,
ol,
li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
}

a {
  color: black;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

button {
  padding: 0;
  background-color: initial;
  border: none;
  cursor: pointer;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

textarea {
  resize: none;
}

section {
  position: relative;
}

input,
select,
button,
textarea {
  font: inherit;
  color: inherit;
  outline: none !important;
}

fieldset {
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-block-start: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
  padding-block-end: 0;
  min-inline-size: min-content;
  border-width: 0;
  border-style: none;
  border-color: transparent;
  border-image: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}

.wrapper {
  min-height: 100vh;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  position: relative;
}

.content {
  flex: 1 0 auto;
  overflow: hidden;
  position: relative;
  z-index: 500;
}

.container,
.container-lg,
.container-sm {
  width: 100%;
  margin: 0 auto;
  padding-right: px(20);
  padding-left: px(20);
  transition: max-width .3s ease;
}

.container {
  @include media-up(577) {
    max-width: px(550);
  }
  @include media-up(769) {
    max-width: px(730);
  }
  @include media-up(1025) {
    max-width: px(970);
  }
  @include media-up(1201) {
    max-width: px(1140);
  }
  @include media-up(1441) {
    max-width: px(1330);
  }
  @include media-up(1621) {
    max-width: px(1480);
  }
  @include media-down(576) {
    max-width: 100%;
  }
}

.container-sm {
  @include media-up(577) {
    max-width: px(550);
  }
  @include media-up(769) {
    max-width: px(730);
  }
  @include media-up(1025) {
    max-width: px(970);
  }
  @include media-up(1441) {
    max-width: px(1210);
  }
  @include media-down(576) {
    max-width: 100%;
  }
}

.container-lg {
  @include media-up(577) {
    max-width: px(550);
  }
  @include media-up(769) {
    max-width: px(730);
  }
  @include media-up(1025) {
    max-width: px(970);
  }
  @include media-up(1201) {
    max-width: px(1180);
  }
  @include media-up(1441) {
    max-width: px(1380);
  }
  @include media-up(1621) {
    max-width: px(1560);
  }
  @include media-down(576) {
    max-width: 100%;
  }
}

.important_none {
  display: none !important;
}

.custom-scrollbar {
  &::-webkit-scrollbar {
    width: px(4);
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #00000081;
  }
}

.bg-gold {
  width: 100%;
  height: 100%;
  box-shadow: inset 0 0 0 1px var(--gold-signature);

  &.bg-filled {
    background-color: var(--gold-signature);
  }

  &__inner {
    width: 100%;
    height: 100%;
    position: relative;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  transition: all .5s ease;
  transition-delay: 1s;
  background-color: var(--baseWhite);

  &.hide {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;

    .loader {
      &__picture {
        transform: scale(.25);
        opacity: 0;
      }

      &__title {
        span {
          opacity: 0;
        }
      }
    }
  }

  &.active {
    &:before {
      width: 100vw;
      height: 100vh;
    }

    .loader {
      &__picture {
        transform: scale(1);
        opacity: 1;
      }

      &__title {
        span {
          opacity: 1;
        }
      }
    }
  }

  &:before {
    content: '';
    display: block;
    width: 0;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    aspect-ratio: 1/1;
    background-color: var(--green-deep);
    transition: all 1.25s ease-in-out;
    transition-delay: .75s;
  }

  &__box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 2;
  }

  &__picture {
    max-width: 200px;
    width: 100%;
    margin-bottom: 20px;
    transform: scale(.25);
    opacity: 0;
    transform-origin: bottom center;
    transition: all .5s ease-in-out;

    &__img {
      display: block;
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }

  &__title {
    color: var(--gold-signature);
    text-align: center;
    font-family: "PP Hatton", sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%;

    span {
      transition: all .5s ease;
      opacity: 0;
    }

    .firs-word {
      transition-delay: .5s;
    }

    .second-word {
      transition-delay: 1s;
    }
  }
}
