.main-title {
  color: var(--ivory-1);
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: "PP Hatton", sans-serif;
  font-size: 68px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: uppercase;
  @include media-down(1620) {
    font-size: 52px;
  }
  @include media-down(1440) {
    font-size: 44px;
  }
  @include media-down(1200) {
    font-size: 38px;
  }
  @include media-down(1024) {
    font-size: 32px;
  }
  @include media-down(768) {
    font-size: 26px;
  }
}

.section-title {
  text-align: center;
  font-family: "PP Hatton", sans-serif;
  font-size: 60px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  color: var(--gold-signature);
  @include media-down(1620) {
    font-size: 52px;
  }
  @include media-down(1440) {
    font-size: 44px;
  }
  @include media-down(1200) {
    font-size: 36px;
  }
  @include media-down(1024) {
    font-size: 30px;
  }
}

.upper-title {
  color: var(--gold-signature);
  font-family: "General Sans", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  text-align: center;
  @include media-down(1440) {
    font-size: 28px;
  }
  @include media-down(1200) {
    font-size: 24px;
  }
  @include media-down(1024) {
    font-size: 20px;
  }
  @include media-down(768) {
    font-size: 18px;
  }
}

.upper-title-icon {
  margin: 0 auto 24px;
  @include media-up(1025) {
    width: 58px;
    height: 60px;
  }

  .icon {
    width: 100%;
    min-width: 100%;
    min-height: 100%;
    object-fit: contain;
  }
}

.title-line {
  max-width: 200px;
  width: 100%;
  margin: 0 auto;
  padding: 24px 0;
  @include media-down(1024) {
    padding: 18px 0;
    max-width: 160px;
  }

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: var(--gold-signature);
  }
}
