.inspired-experiences {
  z-index: 6;
  background-image: url("../img/content/inspired-experiences/inspired-experiences-bg.png");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  &:before,
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: auto;
    position: absolute;
    aspect-ratio: 1.001/1;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    pointer-events: none;
    @include media-down(1024) {
      display: none;
    }
  }

  &:before {
    top: 0;
    left: 0;
    max-width: 670px;
    transform: translate(-35%, -30%) rotate(-55deg) scale(-1, 1);
    background-image: url("../img/content/destinations/lyap-bg-1.png");
  }

  &:after {
    right: 0;
    bottom: 0;
    max-width: 620px;
    transform: translate(48%, 39.5%) rotate(-25deg);
    background-image: url("../img/content/destinations/lyap-bg-2.png");
  }

  &._section-dark-primary {
    @include media-down(1440) {
      padding-block: 60px;
    }
  }

  &__title {
    margin-bottom: 60px;
    @include media-down(1200) {
      margin-bottom: 40px;
    }
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 20px);
    margin: -10px;
    margin-bottom: 60px;
    @include media-down(1200) {
      margin-bottom: 40px;
    }
  }

  &__col {
    flex: 0 0 20%;
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    overflow: hidden;
    aspect-ratio: 1/1;
    @include media-down(768) {
      flex: 0 0 33.333333%;
      width: 33.333333%;
    }
    @include media-down(576) {
      flex: 0 0 50%;
      width: 50%;
    }
    @include media-down(375) {
      flex: 0 0 100%;
      width: 100%;
    }

    &._col-2 {
      flex: 0 0 40%;
      width: 40%;
      aspect-ratio: unset;
      @include media-down(1200) {
        flex: 0 0 60%;
        width: 60%;
      }
      @include media-down(768) {
        flex: 0 0 100%;
        width: 100%;
      }
    }

    &._col-3 {
      flex: 0 0 60%;
      width: 60%;
      aspect-ratio: unset;
      @include media-down(1200) {
        flex: 0 0 80%;
        width: 80%;
        order: -1;
      }
      @include media-down(768) {
        flex: 0 0 100%;
        width: 100%;
      }
    }

    &._empty-col {
      @include media-down(1200) {
        flex: 0 0 40%;
        width: 40%;
        order: -1;
        aspect-ratio: unset;
      }
      @include media-down(768) {
        flex: 0 0 66.66666%;
        width: 66.66666%;
      }
      @include media-down(576) {
        display: none;
      }
    }

    &__image {
      display: block;
      width: 100%;
      height: 100%;
    }

    &__img {
      width: 100%;
      height: 100%;
      aspect-ratio: 1/1;
      object-fit: contain;
    }

    blockquote {
      padding-left: 40px;
      padding-right: 24px;
      line-height: 160%;
      border-left: 1px solid var(--gold-signature);
      @include media-down(1200) {
        padding-left: 24px;
        padding-right: 18px;
      }
      @include media-down(576) {
        padding: 0;
        border: 0;
      }
    }

    p {
      padding-left: 24px;
      @include media-down(1200) {
        padding-left: 18px;
      }
      @include media-down(768) {
        padding: 0;
      }
    }
  }

  &__benefits {
    padding-block: 40px;
    padding-inline: 50px;
    border-width: 1px 0 1px;
    border-style: solid;
    border-color: var(--gold-signature);
    @include media-down(1200) {
      padding-block: 30px;
      padding-inline: 25px;
    }
    @include media-down(1200) {
      border: 0;
    }
    @include media-down(1024) {
      padding: 0;
    }
  }

  &__benefits-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% + 20px);
    margin-inline: -10px;
    @include media-down(1024) {
      flex-wrap: wrap;
      row-gap: 20px;
      justify-content: center;
      align-items: unset;
    }
  }

  &__benefits-col {
    padding-inline: 10px;
    @include media-down(1024) {
      flex: 0 0 33.33333%;

      &:nth-child(5) {
        order: 2;
      }
    }
    @include media-down(576) {
      flex: 0 0 50%;
    }
    @include media-down(375) {
      flex: 0 0 100%;
      width: 100%;
    }
  }

  &__benefit {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: var(--gold-signature);
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
    @include media-down(1200) {
      font-size: 18px;
    }
    @include media-down(1024) {
      padding: 10px;
      border: 1px solid var(--gold-signature);
    }
  }
}
