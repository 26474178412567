.inspired-collection {
  padding-block: 170px;
  position: relative;
  @include media-down(1620) {
    padding-block: 150px;
  }
  @include media-down(1440) {
    padding-block: 120px;
  }
  @include media-down(1200) {
    padding-block: 90px;
  }
  @include media-down(1024) {
    padding-block: 60px;
    padding-inline: 20px;
    background-color: var(--section-dark-bg);
  }

  &__row {
    display: flex;
  }

  &__col {
    flex: 0 0 50%;
    width: 50%;
    @include media-down(1024) {
      flex: 0 0 100%;
      width: 100%;
    }

    &._content-col {
      @include media-down(1024) {
        position: relative;
        z-index: 2;
      }
    }

    &._image-col {
      pointer-events: none;
      @include media-down(1024) {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 752px;
    height: 100%;
    margin-left: auto;
    padding-inline: 20px;
    padding-bottom: 41px;
    @include media-down(1200) {
      padding-bottom: 20px;
    }
    @include media-down(1024) {
      margin: 0 auto;
      padding-block: 40px;
      background: rgba(20, 18, 20, 0.6);
    }
  }

  &__title-line {
    margin-bottom: 36px;
  }

  &__descr-slide-title {
    display: block;
    margin-bottom: 24px;
    color: var(--gray-dark);
    font-size: 36px;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
    text-align: center;
    @include media-down(1440) {
      font-size: 32px;
    }
    @include media-down(1200) {
      font-size: 28px;
    }
    @include media-down(1024) {
      color: var(--baseTextLight);
    }
  }

  &__descr-slide-benefits-row {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% + 48px);
    margin-inline: -24px;
    @include media-down(1200) {
      width: calc(100% + 30px);
      margin-inline: -15px;
      flex-wrap: wrap;
      row-gap: 30px;
    }
  }

  &__descr-slide-benefits-col {
    display: flex;
    align-items: center;
    padding-inline: 24px;
    @include media-down(1200) {
      padding-inline: 15px;
    }
    @include media-down(1024) {
      color: var(--baseTextLight);
    }

    .icon {
      width: 40px;
      min-width: 40px;
      height: 40px;
      min-height: 40px;
      margin-right: 16px;
      fill: var(--gold-signature);
      @include media-down(1024) {
        margin-right: 12px;
        width: 30px;
        min-width: 30px;
        height: 30px;
        min-height: 30px;
      }
    }
  }

  &__descr-slider-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
    padding-inline: 50px;
    @include media-down(1200) {
      padding-inline: 20px;
    }
    @include media-down(1024) {
      justify-content: center;
      gap: 30px;
    }
  }

  &__descr-slider,
  &__image-slider {
    width: 100%;

    .slider-container {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    .swiper-slide {
      transition: opacity .5s ease;
      opacity: 0 !important;

      &.swiper-slide-thumb-active,
      &.swiper-slide-active {
        opacity: 1 !important;
      }
    }
  }

  &__descr-slider {
    margin-bottom: 81px;
    @include media-down(1440) {
      margin-bottom: 60px;
    }
    @include media-down(1200) {
      margin-bottom: 40px;
    }

    .swiper-slide {
      @media (min-width: 1025px) {
        .inspired-collection__descr-slide-title,
        .inspired-collection__descr-slide-benefits-col {
          opacity: 0;
          transition: transform .75s ease, opacity .75s ease;
        }

        .inspired-collection__descr-slide-title {
          transform: translateX(-50px);
        }

        .inspired-collection__descr-slide-benefits-col {
          transform: translateY(50px);
        }

        .inspired-collection__descr-slide-benefits-col {
          &:nth-child(1) {
            transition-delay: .15s;
          }

          &:nth-child(2) {
            transition-delay: .25s;
          }

          &:nth-child(3) {
            transition-delay: .35s;
          }
        }

        &.swiper-slide-active {
          .inspired-collection__descr-slide-title,
          .inspired-collection__descr-slide-benefits-col {
            transform: translateX(0);
            opacity: 1;
          }
        }
      }
    }
  }

  &__image-slider {
    height: 100%;
  }

  &__image-slide {
    pointer-events: none;
    position: relative;
    @include media-down(1024) {
      &:after,
      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
      }

      &:after {
        background: linear-gradient(0deg, rgba(18, 36, 31, 0.60) 0%, rgba(0, 0, 0, 0.00) 75.86%);
      }

      &:before {
        background: linear-gradient(180deg, #12241F 0%, rgba(0, 0, 0, 0.00) 82.29%);
      }
    }
    @media (min-width: 1025px) {
      &.swiper-slide-thumb-active {
        .inspired-collection__image-slide__img {
          transform: translate(-50%, -50%);
        }
      }
    }

    &__img {
      display: block;
      width: 100%;
      max-width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-25%, -50%);
      transition: all .75s ease;
      object-fit: cover;
      @media (max-width: 1024px) {
        transform: translate(-50%, -50%);
      }
    }
  }
}
