.footer {
  position: relative;
  z-index: 500;
  background-color: var(--section-dark-bg);
  color: var(--baseTextLight);

  &__top,
  &__bottom {
    width: 100%;
    border-top: 1px solid var(--gold-signature);
  }

  &__top {
    padding-block: 40px 24px;
    @include media-down(1440) {
      padding-block: 30px 18px;
    }
  }

  &__bottom {
    padding-block: 40px 41px;
    @include media-down(1440) {
      padding-block: 30px;
    }
  }

  &__row {
    display: flex;
    width: calc(100% + 40px);
    margin-inline: -20px;
    row-gap: 6px;
    @include media-down(1440) {
      width: calc(100% + 30px);
      margin-inline: -15px;
    }

    &._space-between {
      justify-content: space-between;
    }

    &._items-center {
      align-items: center;
    }

    &._change-orders-row-laptop-down {
      @include media-down(1440) {
        flex-wrap: wrap;
      }
    }

    &._change-orders-row-tablet-down {
      @include media-down(1200) {
        flex-wrap: wrap;
      }
    }

    &._change-orders-row-mobile-down {
      @include media-down(768) {
        flex-wrap: wrap;
      }
    }

    &:not(:last-child) {
      margin-bottom: 72px;
      @include media-down(1440) {
        margin-bottom: 50px;
      }
      @include media-down(1200) {
        margin-bottom: 30px;
      }
    }
  }

  &__col {
    padding-inline: 20px;

    &._change-orders-col-1-laptop-down,
    &._change-orders-col-2-laptop-down,
    &._change-orders-col-3-laptop-down {
      @include media-down(1440) {
        flex: 0 0 50%;
      }
      @include media-down(1024) {
        flex: 0 0 100%;
      }
    }

    &._change-orders-col-1-tablet-down,
    &._change-orders-col-2-tablet-down,
    &._change-orders-col-3-tablet-down {
      @include media-down(1200) {
        flex: 0 0 50%;
      }
      @include media-down(768) {
        flex: 0 0 100%;
      }
    }

    &._change-orders-col-1-mobile-down {
      @include media-down(768) {
        flex: 0 0 100%;
      }
    }

    &._change-orders-col-2-mobile-down {
      @include media-down(768) {
        flex: 0 0 100%;
      }
    }

    &._change-orders-col-1-laptop-down {
      @include media-down(1440) {
        order: 1;
      }
      @include media-down(1024) {
        order: unset;
      }
    }

    &._change-orders-col-2-laptop-down {
      @include media-down(1440) {
        order: 3;
      }
      @include media-down(1024) {
        order: unset;
      }
    }

    &._change-orders-col-3-laptop-down {
      @include media-down(1440) {
        order: 2;
      }
      @include media-down(1024) {
        order: unset;
        margin: 0;
      }
    }

    &._change-orders-col-1-tablet-down {
      @include media-down(1200) {
        order: 1;
      }
      @include media-down(1200) {
        order: unset;
      }
    }

    &._change-orders-col-2-tablet-down {
      @include media-down(1200) {
        order: 3;
      }
      @include media-down(1200) {
        order: unset;
      }
    }

    &._change-orders-col-3-tablet-down {
      @include media-down(1200) {
        order: 2;
      }
      @include media-down(1200) {
        order: unset;
      }
    }
  }

  &__logo {
    @include media-down(1620) {
      max-width: 280px;
    }
    @include media-down(1440) {
      max-width: 250px;
    }
    @include media-down(1200) {
      max-width: 220px;
    }
    @include media-down(1024) {
      max-width: 190px;
    }
    @include media-down(768) {
      margin-bottom: 20px;
    }
  }

  &__text,
  &__link {
    display: block;
    width: fit-content;
    color: var(--gold-signature);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    @include media-down(1620) {
      font-size: 16px;
    }

    &._text-end {
      margin-left: auto;
      text-align: end;
      @include media-down(768) {
        margin-left: 0;
        text-align: start;
      }
    }
  }

  &__text {
    & > *:not(:last-child),
    &:not(:last-child) {
      margin-bottom: 8px;
      @include media-down(1620) {
        margin-bottom: 6px;
      }
    }
  }

  &__unsubscribe-link {
    display: block;
    width: fit-content;
    position: relative;
    color: var(--ivory-1);
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    text-decoration-line: none;
    white-space: nowrap;
    transition: all .3s ease;
    @include media-up(1025) {
      &:hover {
        color: var(--gold-signature);
        text-decoration-line: none;

        &:before {
          width: 73%;
          top: 55%;
          transform: translate(0, -50%);
          background-color: var(--gold-signature);
        }
      }
    }
    @include media-down(1620) {
      font-size: 20px;
    }
    @include media-down(1440) {
      margin-left: auto;
    }
    @include media-down(1024) {
      font-size: 18px;
      margin-left: 0;
    }

    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      position: absolute;
      left: 0;
      top: 100%;
      transform: translate(0, -100%);
      background-color: var(--ivory-1);
      border-radius: 1px;
      transition: all .35s ease;
      pointer-events: none;
    }
  }

  &__link {
    transition: all .3s ease;

    @include media-up(1025) {
      &:hover {
        color: var(--ivory-1);
      }
    }
  }

  &__bottom-link {
    display: flex;
    align-items: center;
    width: fit-content;
    color: var(--gold-signature);
    font-size: 27px;
    font-style: normal;
    font-weight: 200;
    line-height: 150%;
    text-transform: uppercase;
    transition: all .3s ease;
    @include media-up(1025) {
      &:hover {
        color: var(--ivory-1);

        .footer__bottom-link-icon-inner {
          height: 100%;
        }
      }
    }
    @include media-down(1620) {
      font-size: 24px;
    }
    @include media-down(1024) {
      font-size: 18px;
    }
    @include media-down(768) {
      font-size: 16px;
    }

    &._with-margin {
      margin-left: 89px;
      @include media-down(1620) {
        margin-left: 44px;
      }
      @include media-down(1200) {
        margin-left: 0;
      }
    }

    &-icon {
      margin-right: 25px;
      position: relative;
      @include media-down(1620) {
        margin-right: 15px;
      }
      @include media-down(1024) {
        margin-right: 10px;
      }

      .icon {
        @include media-down(1620) {
          width: 30px;
          min-width: 30px;
          height: 32px;
        }
        @include media-down(1024) {
          width: 24px;
          min-width: 24px;
          height: 26px;
        }
      }

      &-inner {
        display: block;
        width: 100%;
        height: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all .3s ease;
        overflow: hidden;

        .icon {
          max-width: 100%;
          fill: var(--ivory-1);
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  &__socials {
    width: fit-content;
    margin-left: auto;
    @include media-down(768) {
      margin-top: 20px;
      margin-left: 0;
    }
  }
}
