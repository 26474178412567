.logo-secondary {
  display: block;
  max-width: px(336);
  height: fit-content;

  &__icon {
    width: 100%;
    max-width: 100%;
    height: auto;
    object-fit: contain;
  }
}
