$iterate: 5;

.mt-header-height {
  margin-top: var(--header-height);
}

.ms-auto {
  margin-left: auto;
}

.me-auto {
  margin-right: auto;
}

.m-0-auto {
  margin: 0 auto;
}

.m-auto-0 {
  margin: auto 0;
}

.m-auto {
  margin: auto;
}

.m-0 {
  margin: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.me-0 {
  margin-right: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ms-0 {
  margin-left: 0 !important;
}

@for $i from 1 through $iterate {
  .m-#{$i} {
    margin: #{$i}rem;
  }
}

@for $i from 1 through $iterate {
  .mt-#{$i} {
    margin-top: #{$i}rem;
  }
}

@for $i from 1 through $iterate {
  .me-#{$i} {
    margin-right: #{$i}rem;
  }
}

@for $i from 1 through $iterate {
  .mb-#{$i} {
    margin-bottom: #{$i}rem;
  }
}

@for $i from 1 through $iterate {
  .ms-#{$i} {
    margin-left: #{$i}rem;
  }
}

@for $i from 1 through $iterate {
  .my-#{$i} {
    margin-block: #{$i}rem;
  }
}

@for $i from 1 through $iterate {
  .mx-#{$i} {
    margin-inline: #{$i}rem;
  }
}
