.concierge-services-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  padding: 20px;
  position: relative;
  overflow: hidden;
  transition: all .5s ease;
  @media (min-width: 1025px) {
    &:hover {
      .concierge-services-card__title {
        font-size: 23px;
        font-weight: 600;
        @media (max-width: 1620px) {
          font-size: 19px;
        }
        @media (max-width: 1440px) {
          font-size: 18px;
        }
      }

      .concierge-services-card__icon {
        width: 200px;
        opacity: 1;
        @media (max-width: 1620px) {
          width: 150px;
          margin-left: 20px;
        }
        @media (max-width: 1440px) {
          width: 100px;
        }
      }
    }
  }
  @media (max-width: 1024px) {
    aspect-ratio: 1/1;
  }
  @media (max-width: 576px) {
    aspect-ratio: 1.25/1;
  }
  @media (max-width: 375px) {
    aspect-ratio: 1/1.5;
  }

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    transition: all .5s ease;
    background: linear-gradient(360deg, #12241F 0%, rgba(18, 36, 31, 0.00) 54.82%);
    pointer-events: none;
    @media (max-width: 1024px) {
      background: rgba(20, 18, 20, 0.53);
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    object-fit: cover;
  }

  &__row {
    display: flex;
    justify-content: flex-end;
    max-width: 100%;
    width: 100%;
    position: relative;
    z-index: 3;
    transition: all .5s ease;
    @media (max-width: 1024px) {
      justify-content: flex-start;
    }
  }

  &__title {
    display: block;
    width: fit-content;
    color: var(--gold-signature);
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
    text-transform: uppercase;
    position: relative;
    overflow: hidden;
    transition: all .5s ease;
    @media (max-width: 1620px) {
      font-size: 18px;
    }
    @media (max-width: 1440px) {
      font-size: 16px;
    }
    @media (max-width: 1024px) {
      font-weight: 600;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    width: 0px;
    overflow: hidden;
    transition: all .5s ease;
    opacity: 0;
    @media (max-width: 1024px) {
      width: 100px;
      opacity: 1;
      margin-left: 20px;
    }
    @media (max-width: 768px) {
      display: none;
    }


    .icon {
      display: block;
      width: 200px;
      height: 7px;
      fill: var(--gold-signature);
      transition: all .5s ease;
      @media (max-width: 1620px) {
        width: 150px;
      }
      @media (max-width: 1440px) {
        width: 100px;
      }
    }
  }
}
