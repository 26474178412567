.nav {
  width: 100%;
  @include media-down(1200) {
    width: 100%;
    height: 100%;
    padding-top: calc(var(--header-height) + 20px);
    padding-bottom: 20px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2050;
    background-color: var(--green-deep);
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    transition: all .3s ease;
    transition-delay: .3s;

    &.show {
      opacity: 1;
      pointer-events: all;
      visibility: visible;
      transition-delay: .0s;

      .nav__link {
        transform: translateY(0);
        transition-delay: .3s;
      }
    }
  }

  ._hide-tablet {
    @include media-down(1200) {
      display: none;
    }
  }

  &__wrap {
    width: 100%;
    @include media-down(1200) {
      max-width: 970px;
      height: 100%;
      max-height: 100%;
      padding-inline: 20px;
      margin: 0 auto;
      overflow-y: auto;
      transition: max-width .3s ease;
    }
    @include media-down(1024) {
      max-width: 730px;
    }
    @include media-down(768) {
      max-width: 550px;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: calc(100% + px(86));
    margin-inline: px(-43);
    @include media-down(1620) {
      justify-content: center;
      width: calc(100% + px(60));
      margin-inline: px(-30);
    }
    @include media-down(1440) {
      width: calc(100% + px(30));
      margin-inline: px(-15);
    }
    @include media-down(1200) {
      width: 100%;
      margin: 0;
      flex-direction: column;
      align-items: unset;
      justify-content: unset;
    }
  }

  &__col {
    padding-inline: px(43);
    @include media-down(1620) {
      padding-inline: px(30);
    }
    @include media-down(1440) {
      padding-inline: px(15);
    }
    @include media-down(1200) {
      padding: 0;
      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }
  }

  &__list {
    display: flex;
    align-items: center;
    width: calc(100% + px(40));
    margin-inline: px(-20);
    @include media-down(1620) {
      width: calc(100% + px(30));
      margin-inline: px(-15);
    }
    @include media-down(1200) {
      width: 100%;
      margin: 0;
      flex-direction: column;
      align-items: unset;
      justify-content: unset;
    }
  }

  &__list-item {
    padding-inline: px(20);
    @include media-down(1620) {
      padding-inline: px(15);
    }
    @include media-down(1200) {
      width: fit-content;
      padding: 0;
      overflow: hidden;
      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }

    .dropdown-primary {
      width: fit-content;
      height: fit-content;
    }
  }

  &__logo {
    margin-right: 5px;
    @include media-down(1620) {
      max-width: 120px;
    }
  }

  &__lang-btn,
  &__link {
    display: flex;
    align-items: center;
    width: 100%;
    color: var(--gold-signature);
    text-align: center;
    font-style: normal;
    line-height: normal;
    text-transform: uppercase;
    transition: all .3s ease;
    @include media-down(1200) {
      font-size: 24px;

      br {
        display: none;
      }
    }
  }

  &__lang-dropdown-hover-primary {
    .dropdown-hover-primary__body {
      left: unset;
      right: 0;
    }
  }

  &__link {
    @include media-up(1025) {
      &:hover {
        width: calc(100% + 0.78999px);
        margin-inline: -0.39499px;
        font-weight: 300;

        .icon {
          &-phone {
            margin-right: calc(8px - 0.39499px);
            margin-left: 0.39499px;
          }
        }
      }
    }
    @include media-down(1200) {
      transform: translateY(100%);
    }

    .icon {
      &-phone {
        margin-right: 8px;
      }
    }
  }

  &__lang-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    min-width: 48px;
    height: 48px;
    padding: 10px;
    line-height: 1;
    font-size: 18px;
    font-weight: 200;
    @include media-up(1025) {
      &:hover {
        font-weight: 400;
      }
    }
    @include media-down(1440) {
      font-size: 16px;
      width: 44px;
      min-width: 44px;
      height: 44px;
    }
  }
}
